import { useMutation } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";

export const useMutationExportOrganizationCsv = () => {
  return useMutation(
    async (query: { includeDeposits: boolean; includeWithdrawals: boolean; includeInternal: boolean }) =>
      (await apig.csv.getOrganizationTransactionsCsv(query)).data
  );
};

export const useMutationExportOpportunityCsvUser = () => {
  return useMutation(
    async (query: { opportunityId: string }) => (await apig.csv.getOpportunityTransactionsCsvUser(query)).data
  );
};

export const useMutationExportOpportunityCsvAdmin = () => {
  return useMutation(
    async (query: { opportunityId: string }) => (await apig.csv.getOpportunityTransactionsCsvAdmin(query)).data
  );
};
